import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const GoldenWrapper = styled.div<{
  zIndex?: string;
  left?: string;
}>`
  width: 128px;
  height: 64px;
  background: linear-gradient(134.58deg, #ac9470 0%, #cbb898 78.65%);
  position: absolute;
  top: 0;
  right: 0;
  left: ${(props) => props.left || "auto"};
  z-index: ${(props) => props.zIndex || 1};
  ${mq["md"]} {
    left: auto;
  }
`;
