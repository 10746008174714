import styled from "@emotion/styled";
import { Link } from "gatsby";
import {
  GatsbyImage,
  IGatsbyImageData,
  ImageDataLike,
  getImage,
} from "gatsby-plugin-image";
import { mq } from "styles/media-query";

const Post = styled.div`
  max-width: 1040px;
  margin: 0 auto 40px;
  padding: 0 16px 40px;

  > .gatsby-image-wrapper {
    border-radius: 15px;
    opacity: 0.9999; //safari overflow bug fix
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGreen};
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;

    > .gatsby-image-wrapper {
      margin-right: 40px;
      flex-shrink: 0;
    }
  }
`;

const Title = styled(Link)`
  > h4 {
    transition: color 0.3s ease-in-out;
    margin: 0;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.tomatoRed};
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.h4};
    line-height: 39px;

    ${mq["lg"]} {
      font-size: 20px;
    }

    ${mq["md"]} {
      font-size: 18px;
    }
  }

  :hover,
  :focus-visible {
    > h4 {
      color: ${({ theme }) => theme.colors.darkGolden};
    }
  }
`;

const Date = styled.p`
  margin: 8px 0;
  background: linear-gradient(135deg, #ac9470 0%, #cbb898 78.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
  font-weight: 500;
`;

const Excerpt = styled.p`
  margin: 0 0 8px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;

const ReadMore = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.tomatoRed};
  transition: color 0.3s ease-in-out;

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.darkGolden};
  }
`;

type PostProps = {
  image: ImageDataLike;
  title: string;
  slug: string;
  date: string;
  excerpt: string;
};

export const BlogPostDisplay = ({
  image,
  title,
  slug,
  date,
  excerpt,
}: PostProps) => {
  return (
    <Post>
      <GatsbyImage image={getImage(image) as IGatsbyImageData} alt={title} />
      <div>
        <Title to={slug}>
          <h4>{title}</h4>
        </Title>

        <Date>{date}</Date>

        <Excerpt>{excerpt}</Excerpt>

        <ReadMore to={slug}>Read More</ReadMore>
      </div>
    </Post>
  );
};
