import React from "react";
import { GoldenWrapper } from "./golden-back";

interface Props {
  zIndex?: string;
  left?: string;
}

const GoldenBack = ({ zIndex, left }: Props) => {
  return <GoldenWrapper left={left} zIndex={zIndex}></GoldenWrapper>;
};

export default GoldenBack;
