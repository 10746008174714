import styled from "@emotion/styled";
import { Link } from "gatsby";

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 64px 0;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    + a {
      margin-left: 8px;

      @media (min-width: 600px) {
        margin-left: 16px;
      }
    }
  }
`;

const Btn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin-right: 8px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.darkYellow};
  border-radius: 50%;
  transition: border-color 0.3s ease-in-out;

  :last-of-type {
    margin-right: 0;
  }

  :hover,
  :focus-visible {
    border-color: ${({ theme }) => theme.colors.darkGolden};
  }

  @media (min-width: 600px) {
    margin-right: 16px;
  }
`;

const Num = styled(Link)`
  padding: 3px 9px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  margin: 0 4px;
  border-radius: 4px;
  font-weight: 600;

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colors.lightYellow};
  }

  &.blog-pagination-active {
    background-color: ${({ theme }) => theme.colors.tomatoRed};
    color: ${({ theme }) => theme.colors.white};
  }

  @media (min-width: 600px) {
    margin: 0 8px;
    padding: 4px 12px;
  }
`;

type PaginationProps = {
  currentPage: number;
  numPages: number;
};

const getStart = (currentPage: number, numPages: number) => {
  if (currentPage <= 4) return 1;
  if (currentPage + 2 >= numPages) return numPages - 4;
  else return currentPage - 2;
};

export const Pagination = ({ currentPage, numPages }: PaginationProps) => {
  if (!numPages || !currentPage) return null;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;

  const first = "/blog/";
  const last = `/blog/${numPages}/`;

  const prev = currentPage === 2 ? first : `/blog/${currentPage - 1}/`;
  const next = `/blog/${currentPage + 1}/`;

  const start = getStart(currentPage, numPages);
  const nums = Array.from({ length: numPages > 5 ? 5 : numPages }).map(
    (_, i) => i + start
  );

  return (
    <StyledPagination>
      {numPages > 5 ? (
        <Btn
          to={first}
          style={{ display: isFirst ? "none" : "block" }}
          data-type="button"
        >
          &#10096;&#10096;
        </Btn>
      ) : (
        ""
      )}
      <Btn
        to={prev}
        style={{ display: isFirst ? "none" : "block", fontSize: "0.8975rem" }}
        data-type="button"
      >
        &#10094;
      </Btn>
      <div>
        {nums.map((num) => (
          <Num
            to={num === 1 ? "/blog/" : `/blog/${num}/`}
            key={num}
            activeClassName="blog-pagination-active"
            data-type="button"
          >
            {num}
          </Num>
        ))}
      </div>
      <Btn
        to={next}
        style={{ display: isLast ? "none" : "block", fontSize: "0.8975rem" }}
        data-type="button"
      >
        &#10095;
      </Btn>
      {numPages > 5 ? (
        <Btn
          to={last}
          style={{ display: isLast ? "none" : "block" }}
          data-type="button"
        >
          &#10097;&#10097;
        </Btn>
      ) : (
        ""
      )}
    </StyledPagination>
  );
};
