import { BlogPostDisplay } from "@/BlogPostDisplay";
import AlternativeHeroBanner from "@/common/alternative-hero-banner";
import { Seo } from "@/common/Seo";
import { theme } from "@/GlobalStyles";
import { Pagination } from "@/Pagination";
import { graphql } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";
import { Fragment } from "react";

type BlogPageProps = {
  data: {
    allMdx: {
      nodes: {
        frontmatter: {
          title: string;
          date: string;
          description?: string;
          cover: { childImageSharp: ImageDataLike };
        };
        excerpt: string;
        fields: {
          slug: string;
        };
      }[];
    };
  };
  pageContext: {
    currentPage: number;
    limit: number;
    numPages: number;
    skip: number;
  };
};

const BlogPage = ({ data, pageContext }: BlogPageProps) => {
  const { currentPage, numPages } = pageContext;

  const allBlogPosts = data.allMdx.nodes;

  return (
    <Fragment>
      <AlternativeHeroBanner
        IntroHeader="Blog"
        NavigateTo="Blog"
        NavigateFrom="Home"
        PageName="Blog"
        BgHero="blog-hero.jpg"
        introHeadColor={theme.colors.tomatoRed}
      />
      <section>
        {allBlogPosts.map((post, i) => (
          <BlogPostDisplay
            key={i}
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            slug={`/blog/${post.fields.slug}`}
            excerpt={post.frontmatter.description || post.excerpt}
            image={post.frontmatter.cover?.childImageSharp}
          />
        ))}
      </section>
      {numPages > 1 ? (
        <Pagination currentPage={currentPage} numPages={numPages} />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogPage($limit: Int!, $skip: Int!) {
    allMdx(
      filter: { fields: { collection: { eq: "blogs" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        fields {
          slug
        }
        excerpt(pruneLength: 123)
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          description
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 322
                height: 232
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`;

export const Head = () => {
  return (
    <Seo
      title="Blog - Pearl Pediatric Dentistry and Orthodontics"
      description="Our blog offers wide range of articles related to pediatric dentistry and orthodontics. Contact us today!"
    />
  );
};
